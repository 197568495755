/**
 * @name: index
 * @author: Gzm
 * @date: 2023-05-30 14:39
 * @description：销售管理-旅游-旅游管理API
 * @update: 2023-05-30 14:39
 */

import {get, postJ} from "@/request";
import {IPageRes} from "@/apis/page";
import {ITourCreate, ITourQuery} from "@/apis/travelManage/types";
import {IContestCreate} from "@/apis/competitionManage/types";

/**
 * 旅游规格名称列表
 */
export const queryTourSpecsValueListApi = ()=> get(`/golf/tourSpecsValue/queryTourSpecsValueLsit`)

/**
 * 旅游规格属性列表
 * @param specsValueId 规格名称表id
 */
export const queryTourSpecsListApi = (specsValueId:string)=> get(`/golf/tourSpecs/queryTourSpecsList`,{specsValueId})

/**
 * 旅游分页查询列表
 */
export const tourQueryApi = (param:ITourQuery)=> get<IPageRes<any[]>>("/golf/tour/query", param)

/**
 * 旅游地点列表
 */
export const queryTourLocationListApi = (param:any)=> get(`/golf/tourLocation/queryTourLocationList`,param)

/**
 * 创建
 */
export const tourCreateApi = (param:ITourCreate)=> postJ(`/golf/tour/create`,param)

/**
 * 查询详情
 */
export const tourDetailApi = (id:string)=> get(`/golf/tour/detail/${id}`)

/**
 * 上下价
 */
export const tourUpAndDownShelfApi = (param:any)=> postJ(`/golf/tour/upAndDownShelf`,param)


/**
 * 修改
 */
export const tourModifyApi = (param:IContestCreate)=> postJ(`/golf/tour/modify`,param)
